<template>
  <div>
    <h3 class="mb-5 mt-10">
      {{ $t("Contacts") }}
    </h3>
    <vs-card>
      <div slot="header">
        <h3 class="pl-3 py-3">Importar contatos</h3>
      </div>

      <form-wizard
        color="rgba(var(--vs-primary), 1)"
        errorColor="rgba(var(--vs-danger), 1)"
        :title="null"
        :subtitle="null"
        stepSize="xs"
        @on-complete="popupActive = true"
        ref="wizard"
      >
        <tab-content
          title="Importar"
          icon="feather icon-upload"
          :before-change="validateStep1"
        >
          <form data-vv-scope="step-1">
            <vs-row class="mt-5">
              <vs-col vs-w="12">
                <vs-select
                  v-model="currentChat"
                  name="campaign_chatbot"
                  label="Chatbot"
                  v-validate="'required'"
                >
                  <vs-select-item
                    v-for="(item, index) in chatOptions"
                    :key="index"
                    :text="item.label"
                    :value="item.value"
                  />
                </vs-select>
              </vs-col>
              <vs-col vs-w="12" vs-type="flex" vs-align="center" class="mt-5">
                <input
                  type="file"
                  id="fileUpload"
                  ref="fileUpload"
                  accept=".csv"
                  class="hidden"
                  name="fileUpload"
                  v-validate="'required'"
                  v-on:input="handleFilesBeforeUpload"
                />
                <vs-button
                  color="primary"
                  icon-pack="feather"
                  icon="icon-upload"
                  icon-after
                  @click="$refs.fileUpload.click()"
                >
                  Upload planilha
                </vs-button>
                <p class="text-base pl-5">
                  Faça o upload de uma planilha com os dados dos contatos para
                  serem importados. Verifique o padrão da planilha com
                  <a
                    href="https://docs.google.com/spreadsheets/d/1aK2GLu0ou3KKWOmop7q_K-ZI3VZ9p60kLr2F-q5YDuE/edit#gid=0"
                    target="_blank"
                    >nossa planilha de exemplo.</a
                  >
                </p>
              </vs-col>
              <vs-col
                vs-w="12"
                class="mt-3"
                :class="{ invisible: !fileToUpload }"
              >
                <p class="text-base">
                  {{ fileToUpload ? fileToUpload.name : "examplecsv.csv" }}
                </p>
              </vs-col>
              <vs-col vs-w="12" class="mt-5">
                <vs-checkbox
                  color="primary"
                  v-model="lgpd"
                  class="lgpd text-base"
                  icon-pack="feather"
                  icon="icon-check"
                  name="lgpd"
                  v-validate="'required'"
                >
                  <span>
                    Na Lei Geral de Proteção de Dados (LGPD) se faz necessário o
                    registro de uma Base Legal associada à coleta e utilização
                    de dados de um lead recebido. Ao continuar a importação,
                    você garante que possui o Consentimento, Legítimo Interesse
                    ou Contrato Preexistente bem como o OPT-IN para a
                    comunicação de cada um desses contatos. Ao continuar você
                    concorda com os
                    <a
                      href="https://www.duotalk.io/termos-de-uso/"
                      target="_blank"
                      >{{ $t("TermsOfService") }}</a
                    >
                    {{ $t("And") }}
                    <a
                      href="https://www.duotalk.io/politica-de-privacidade/"
                      target="_blank"
                      >{{ $t("PrivacyPolicy") }}</a
                    >.
                  </span>
                </vs-checkbox>
                <p class="text-danger mt-5" v-if="agreeToTerms">
                  {{ $t("CampaignAgreeBeforeContinueCSV") }}
                </p>
              </vs-col>
            </vs-row>
          </form>
        </tab-content>
        <tab-content
          title="Revisar"
          icon="feather icon-eye"
          :before-change="validateStep2"
        >
          <form data-vv-scope="step-2">
            <vs-row class="mt-5">
              <vs-col vs-w="12">
                <p class="text-base">
                  Arquivo:
                  {{ fileToUpload ? fileToUpload.name : "examplecsv.csv" }}
                </p>
                <p class="text-base pt-2">Contatos: {{ totalContacts }}</p>
              </vs-col>
              <vs-col vs-w="12" class="mt-6">
                <vs-table stripe :data="contactsList">
                  <template v-slot:header>
                    <h5 class="pb-2">Pré-visualização</h5>
                  </template>
                  <template v-slot:thead>
                    <vs-th>#</vs-th>
                    <vs-th>Nome</vs-th>
                    <vs-th>Email</vs-th>
                    <vs-th>Telefone</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="indextr + 1">
                        {{ indextr + 1 }}
                      </vs-td>
                      <vs-td :data="data[indextr][0]">
                        {{ data[indextr][0] }}
                      </vs-td>
                      <vs-td :data="data[indextr][1]">
                        {{ data[indextr][1] }}
                      </vs-td>
                      <vs-td :data="data[indextr][2]">
                        {{ data[indextr][2] }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vs-col>
            </vs-row>
          </form>
        </tab-content>
        <tab-content title="Finalizar" icon="feather icon-check">
          <vs-row>
            <vs-col vs-w="12">
              <p class="text-base">
                Arquivo:
                {{ fileToUpload ? fileToUpload.name : "examplecsv.csv" }}
              </p>
            </vs-col>
            <vs-col vs-w="12" class="mt-5">
              <div class="w-96">
                <CustomTag
                  :label="'<b>Importados com sucesso:</b> ' + totalImported"
                  color="success"
                />
              </div>
            </vs-col>
            <vs-col vs-w="12" class="mt-5">
              <div class="w-96">
                <CustomTag
                  :label="'<b>Atualizados com sucesso:</b> ' + totalUpdated"
                  color="success"
                />
              </div>
            </vs-col>
            <vs-col vs-w="12" class="mt-3">
              <div class="w-96">
                <CustomTag
                  :label="'<b>Erros de importação:</b> ' + totalImportedError"
                  color="danger"
                />
              </div>
            </vs-col>
          </vs-row>
        </tab-content>

        <template slot="footer" slot-scope="props">
          <div class="flex justify-between">
            <vs-button
              icon-pack="feather"
              icon="icon-arrow-left"
              @click="props.prevTab()"
              :class="{
                invisible: !(props.activeTabIndex > 0 && !props.isLastStep),
              }"
            >
              {{ $t("Back") }}
            </vs-button>
            <vs-button
              icon-pack="feather"
              icon="icon-arrow-right"
              icon-after
              @click="props.nextTab()"
              v-if="!props.isLastStep"
            >
              {{ props.activeTabIndex === 1 ? "Importar" : $t("Next") }}
            </vs-button>
            <vs-button
              v-else
              icon-pack="feather"
              icon="icon-send"
              icon-after
              @click="$router.push({ name: 'contacts' })"
            >
              {{ $t("Finish") }}
            </vs-button>
          </div>
        </template>
      </form-wizard>
    </vs-card>

    <vs-popup title="Confirmar importação" :active.sync="popupConfirmImport">
      <vs-row>
        <vs-col>
          <p class="text-xl text-center">
            Ao confirmar, os contatos da planilha entrarão em processo de
            importação e aqueles que forem importados com sucesso<br />não
            poderão ser descartados.<br />
            Deseja prosseguir?
          </p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" class="mt-5 gap-x-3">
          <vs-button
            color="primary"
            icon-pack="feather"
            icon="icon-check"
            icon-after
            @click="popupConfirm"
          >
            Sim, confirmar
          </vs-button>
          <vs-button
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            icon-after
            @click="popupConfirmImport = false"
          >
            Cancelar
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import CustomTag from "../../../components/CustomTag.vue";
import chat from "../inbox/Chat.vue";
export default {
  name: "ImportContacs",
  components: {
    CustomTag,
    FormWizard,
    TabContent,
  },
  data() {
    return {
      popupConfirmImport: false,
      popupActive: false,
      lgpd: false,
      fileToUpload: null,
      totalValidContacts: 0,
      contactsList: [],
      checkTheNumbers: false,
      delimiter: "",
      totalContacts: 0,
      currentChat: null,
      csvHeader: [],
      totalImported: 0,
      totalImportedError: 0,
      totalUpdated: 0,
      agreeToTerms: null,
    };
  },
  computed: {
    chat() {
      return chat;
    },
    chatOptions() {
      return this.$store.state.chat.chats;
    },
  },
  methods: {
    async getChats() {
      await this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then(async (response) => {
          for (var i = 0; i < response.data.data.length; i++) {
            this.$store.dispatch("chat/setChatsItem", {
              label: response.data.data[i].name,
              value: response.data.data[i]._id,
            });
          }
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: this.$t("UnexpectedError"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    validateStep1() {
      return new Promise((resolve, reject) => {
        this.agreeToTerms = false;
        if (!this.lgpd) {
          reject("correct all values");
          this.agreeToTerms = true;
        }
        this.$validator.validateAll("step-1").then((result) => {
          if (result) {
            resolve(true);
          } else {
            reject("correct all values");
          }
        });
      });
    },
    validateStep2() {
      return new Promise((resolve, reject) => {
        if (!this.popupConfirmImport) {
          this.popupConfirmImport = true;
          return reject("correct all values");
        }
        this.popupConfirmImport = false;

        this.createFormData().then((result) => {
          if (result.status === 200) {
            resolve(true);
          } else {
            reject("correct all values");
          }
        });
      });
    },
    popupConfirm() {
      this.$refs.wizard.nextTab();
    },
    async createFormData() {
      let fd = new FormData();
      fd.append("content", this.fileToUpload);
      fd.append("delimiter", this.delimiter);
      fd.append("csvHeaders", JSON.stringify(this.csvHeader));
      fd.append("chat", this.currentChat);
      fd.append("acc", this.$store.state.acc.current_acc.id);
      fd.append("optIn", this.lgpd);

      const response = await this.$http.post(
        "/p/chat/import/contacts/" + this.$store.state.acc.current_acc.id,
        fd,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.auth.accessToken,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        this.totalImported = response.data.data.totalImported;
        this.totalImportedError = response.data.data.totalImportedError;
        this.totalUpdated = response.data.data.totalUpdated;

        this.$vs.notify({
          time: 2500,
          title: this.$t("Success"),
          text: "Contatos importados com sucesso.",
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
          position: "top-right",
        });
      } else {
        this.$vs.notify({
          time: 6000,
          title: this.$t("Error"),
          text: `${this.$t("CSVStructureError")} ${response.message}`,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
      }

      return response;
    },
    guessDelimiters(text) {
      const possibleDelimiters = [";", ",", "\n", "|"];
      return possibleDelimiters.filter(weedOut);

      function weedOut(delimiter) {
        let cache = -1;
        return text.split("\n").every(checkLength);

        function checkLength(line) {
          if (!line) {
            return true;
          }

          const length = line.split(delimiter).length;
          if (cache < 0) {
            cache = length;
          }
          return cache === length && length > 1;
        }
      }
    },
    handleFilesBeforeUpload(e) {
      this.totalValidContacts = 0;
      this.contactsList = [];
      this.checkTheNumbers = false;
      this.invalidNumbersIndex = [];
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.fileToUpload = files[0];
      this.getBase64();
    },
    getBase64() {
      const thisIns = this;
      const reader = new FileReader();

      reader.readAsDataURL(this.fileToUpload);
      reader.onload = function () {
        thisIns.contactsFileBase64 = reader.result;
        const csvData = Buffer.from(
          reader.result.replace("data:text/csv;base64,", ""),
          "base64"
        )
          .toString()
          .split("\n");

        thisIns.totalContacts = csvData.length;

        const delimiter = thisIns.guessDelimiters(csvData[0]);
        if (delimiter.length > 0) {
          thisIns.delimiter = delimiter[0];

          let j = 0;
          for (let i = 0; i < 11; i++) {
            if (!csvData || !csvData[i] || typeof csvData[i] !== "string")
              continue;
            if (csvData[i]) {
              let rowEl = csvData[i]
                .replace("\r", "")
                .replace("\n", "")
                .replace("\t", "")
                .replace(/['']+/g, "")
                .replace(/['"]+/g, "")
                .split(delimiter[0]);
              if (i > 0) {
                if (rowEl[0] && rowEl[0].length > 0) {
                  j++;
                  if (
                    rowEl[1] &&
                    !thisIns.checkTheNumbers &&
                    /[a-zA-Z]/g.test(rowEl[1])
                  )
                    thisIns.checkTheNumbers = true;
                  if (rowEl[1] && /[a-zA-Z]/g.test(rowEl[1]))
                    thisIns.invalidNumbersIndex.push(i - 1);
                  if (rowEl[2])
                    rowEl[2] = String(
                      rowEl[2]
                        .replace("\r", "")
                        .replace("\n", "")
                        .replace("\t", "")
                        .replace('"', "")
                        .replace("'", "")
                    );
                  thisIns.contactsList.push(rowEl);
                }
              } else thisIns.csvHeader = rowEl;
            }
          }
          thisIns.totalValidContacts = j;
        }
      };

      reader.onerror = function (error) {
        thisIns.$vs.notify({
          time: 2500,
          title: thisIns.$t("Error"),
          text: `${thisIns.$t("UnexpectedError")} - ${error}`,
          iconPack: "feather",
          icon: "icon-success-circle",
          color: "danger",
          position: "top-right",
        });
      };
    },
  },
  mounted() {
    if (
      !this.$store.state.chat.chats ||
      this.$store.state.chat.chats.length === 0
    )
      this.getChats();
  },
};
</script>
<style>
.lgpd .vs-checkbox {
  margin-right: 20px !important;
}
</style>
